@font-face {
  font-family: "Neutra Text TF";
  src: /*local("Neutra Text TF"),*/
          url('../public/fonts/NeutraTextTF-Book.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;

}

@font-face {
  font-family: 'Sana Sans Alt';
  src: /*local("SanaSansAlt-Regular"),*/
          url('../public/fonts/SanaSansAlt-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: /*local("SanaSansAlt-Medium"),*/
          url('../public/fonts/SanaSansAlt-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sana Sans Alt';
  src: //local("SanaSansAlt-Bold"),
          url('../public/fonts/SanaSansAlt-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

li {
  padding-bottom: 16px;
}

.unblur {
  animation: unblur 0.2s linear;
}

@keyframes unblur {
  from {
    filter: blur(20px);
  }
  to {
    filter: blur(0);
  }
}

.image-gallery.fullscreen-modal {
  z-index: 1201 !important;
  //position: absolute !important;
}

.custom-landscape > .image-gallery-content.fullscreen {
  top: unset !important;
  left: 50%;
  transform: translateX(-50%) !important;
}


.tableOfCountentsBox {

  * {
    a {
      text-decoration: none;
    }

    li {
      padding-bottom: 0;
    }
  }

  > ul {

    //ul {
    //  padding-inline-start: 20px;
    //
    //  li:first-child {
    //    list-style: circle;
    //    margin-left: 10px;
    //  }
    //}

    > li {
      > p:first-child {
        font-weight: 500;
        font-size: 1.25rem;
      }

      > ul {
        padding-inline-start: 20px;

        > li {
          list-style: disc;
          margin-left: 10px;

          li {
            list-style: circle;
            padding-left: 0;
          }

        }
      }
    }
  }

}


.embla {
  --slide-size: 100%;
  padding: 16px;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  display: flex;
  flex-direction: row;
  height: auto !important;
  transition: height 0.2s;
  align-items: flex-start; /* Add this */

}

.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  position: relative;
}
//
//.embla_sample {
//
//  .embla__viewport_sample {
//    overflow: hidden;
//    .embla__container_sample {
//      display: flex;
//
//      //flex-direction: row;
//      //height: auto !important;
//      //margin-left: calc(var(--slide-spacing) * -1);
//
//      //align-items: flex-start; /* Add this */
//      .embla__slide_sample {
//        flex: 0 0 100%;
//        //min-width: 0;
//        //padding-left: var(--slide-spacing);
//        position: relative;
//      }
//    }
//  }
//}
//



.whatsapp_widget_wrapper {
  font-family: 'Sana Sans Alt', "Helvetica Neue", Arial, sans-serif;
  z-index: 1000;
  bottom: 16px !important;
  right: 16px !important;
}

.whatsapp_widget_footer_btn {
  //text-transform: uppercase;
  font-family:  'Sana Sans Alt', "Helvetica Neue", Arial, sans-serif;
  padding-top: 10px;
  &::after {
    content: '';
    margin-left: 8px;
    width: 20px;
    height: 20px;
    display: block;
    float: right;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjRwdCIgaGVpZ2h0PSIyNHB0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHZlcnNpb249IjEuMSI+CiAgICA8Zz48cGF0aCBmaWxsPSJ3aGl0ZSIgIGQ9Ik0yLjAxIDIxIDIzIDEyIDIuMDEgMyAyIDEwbDE1IDItMTUgMnoiLz48L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}

.whatsapp_widget_header_icon img {
  width: 56px;
  height: 56px;
}
